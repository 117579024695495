<template>
  <div class="login-wrap">
    <div class="top-form">
      <img class="logo-img" :src="imgLogo">
      <el-button
            @click="navofficialsite"
            class="access-btn"
            size="small"
            type="primary"
            >访问无觅官网</el-button>
      <p class="split-line"></p>
    </div>
    <div class="login-form">
      <div class="title-form">
        <h2>登 录</h2>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="手机验证码" name="verification">
          <verification :phoneNumber="phoneNo"/>
        </el-tab-pane>
        <el-tab-pane label="账号密码" name="account">
          <account :phoneNumber="phoneNo"/>
        </el-tab-pane>
      </el-tabs>
      <span class="explorer-form">建议您使用Chrome、Firefox、Edge等最新版本浏览器访问控制台</span>
    </div>
  </div>
</template>
<script>
import Account from "./Account/index.vue";
import Verification from "./Verification/index.vue";
import navigation from "@/utils/navigation";
import logo from "../../assets/logo.png";
import { officalWebsite } from "@/utils/public";
export default {
  data() {
    return {
      activeName: "verification",
      imgLogo: logo,
      phoneNo: "",
    };
  },
  components: {
    Account,
    Verification,
  },
  methods: {
    navsubaccount() {
      navigation.navigateTo({
        url: "sublogin",
      });
    },
    navofficialsite() {
      window.location.href = officalWebsite;
    }
  },
  mounted() {
    let pn = this.$route.query.phoneNumber;
    if (pn != undefined && pn != "") {
      //console.log("route params: " + pn);
      this.phoneNo = pn;
    }
  }
};
</script>
<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  position:relative;

  .top-form {
    width: 100%;
    height: 60px;
    position: absolute;

    .logo-img {
      width: 60px;
      height: 60px;
      margin-left: 50px;
      vertical-align: middle;
		  text-align: center;
    }

    .access-btn {
      float: right;
      margin-top: 15px;
      margin-right: 50px;
      background:rgba(103, 149, 235, 1);
    }

    .split-line{
      height: 60px;
      //margin-top: 60px;
      border-top: 1px solid #E5E5E5;
    }
  }

  .left-page {
    width: 50%;
    height: 100%;
    background: rgba(70, 81, 96, 1);

    .logo-form {
      width: 100%;
      height: 100px;
      padding-top: 50px;
    }

    .logo-img {
      width: 100px;
      height: 80px;
      margin-right: 10px;
      vertical-align: middle;
		  text-align: center;
    }

    .logo-text {
      width: 100px;
      height: 80px;
      color: #fff;
      font-size: 30px;
      font-weight: 600;
      text-align: left;
      vertical-align: middle;
    }

    .intr-form {
      padding-top: 20px;
      padding-left: 10%;
      font-size: 20px;
      font-weight: 300;
      color: #fff;
    }

    .img-form {
      width: 90%;
      height: 50%;
      color: #000;
      padding-left: 5%;
      padding-top: 10%;
    }
  }
  
  .login-form {
    width: 600px;
    height: 618px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: solid 1px #E5E5E5;
    box-sizing: border-box;
    padding: 45px 20px;
    position: absolute;
    top: 100px;
    bottom: 0;
    left:50%;
    margin-left:-300px;
    box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
          rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
          rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
          
    .title-form {
      text-align: center;
      color: #000;
      margin-bottom: 25px;
    }
    .el-tabs {
      width: 400px;
      margin: auto;
    }
    ::v-deep .el-tabs__nav {
      transform: translateX(114px) !important;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
    ::v-deep .el-tabs__item.is-active {
      //   color: linear-gradient(
      //     90deg,
      //     rgba(74, 99, 240, 0.93) 0%,
      //     rgba(236, 179, 242, 1) 100%
      //   );
      color: rgba(74, 99, 240, 0.93);
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #4a63f0ed;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
    .subaccount {
      position: absolute;
      bottom: 25px;
      right: 42px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
      border-bottom: 1px solid rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
    .explorer-form {
      font-size: 10px;
      color: #B4B4B4;
      display:block;
      text-align: center;
    }
  }
}
</style>