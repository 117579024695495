<template>
  <div class="account-wrap">
    <el-form ref="loginForm" :rules="loginRules" :model="loginForm">
      <el-form-item prop="phonenumber">
        <el-input
          ref="phonenumber"
          clearable
          v-model="loginForm.phonenumber"
          :placeholder="placeholder"
          class="input-with-select"
        >
          <!-- <el-select
            @change="selectchange"
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="主账号" value="1"></el-option>
            <el-option label="子账号" value="2"></el-option>
          </el-select> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          placeholder="请输入密码"
          ref="password"
          clearable
          type="password"
          show-password
          v-model="loginForm.password"
        >
        </el-input>
      </el-form-item>
      <!--<div class="remenberpass">
        <el-checkbox>记住密码</el-checkbox>
        <span @click="navresetpass" class="forgetpass">忘记密码？</span>
      </div>-->
      <el-button
        :loading="loading"
        class="loginbtn"
        type="primary"
        @click.native.prevent="handleLogin"
        native-type="submit"
        >登录</el-button
      >
      <div class="otherlogin">
        <div @click="loginwx" class="weixinlogin">
          <i class="iconfont icon-weixin"></i
          ><span class="wxtext">微信登录</span>
        </div>
        <!--<div class="qqlogin">
          <i class="iconfont icon-qq"></i><span class="qqtext">QQ登录</span>
        </div>-->
      </div>
      <p class="accounttext">
        <span @click="navresetpass" class="forgetpass">忘记密码 </span>
        <span @click="navregister" class="register"> 立即注册</span>
      </p>
    </el-form>
  </div>
</template>
<script>
import navigation from "../../../utils/navigation";
import { isPhonenumber, isSubNumber } from "../../../utils/validate";
import md5 from "js-md5";
export default {
  data() {
    const validatePhonenumber = (rule, value, callback) => {
      if (this.select == 1) {
        if (!isSubNumber(value)) {
          callback(new Error("请输入正确的手机号或者账号"));
        } else {
          callback();
        }
      } else {
        if (!isSubNumber(value)) {
          callback(new Error("用户名中不能包含中文或特殊字符"));
        } else {
          callback();
        }
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      select: "1",
      placeholder: "请输入账号",
      loginForm: {
        phonenumber: "",
        password: "",
      },
      loginRules: {
        phonenumber: [
          { required: true, trigger: "blur", validator: validatePhonenumber },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      loading: false,
      wxloginurl: "",
    };
  },
  props: ["phoneNumber"],
  watch: {
    $route(to, from) {
      console.log(to.path);
    },
    phoneNumber(val) {
      this.loginForm.phonenumber = val;
      this.setFocus();
    },
  },
  methods: {
    selectchange(val) {
      this.$refs.loginForm.resetFields();
      if (val == 1) {
        this.placeholder = "请输入账号";
      } else {
        this.placeholder = "请输入用户名";
      }
    },
    navregister() {
      navigation.navigateTo({
        url: "register",
        params: { phonenumber: this.loginForm.phonenumber },
      });
    },
    navresetpass() {
      navigation.navigateTo({
        url: "forgetpass",
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.select == 1) {
            const data = {
              Type: 2,
              PhoneNumber: this.loginForm.phonenumber,
              Password: md5(this.loginForm.password),
            };
            //主账号登录
            this.$store
              .dispatch("user/login", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$router.push({
                    path: "/",
                  });
                }
                if (res.RetCode == 9) {
                  this.$message.error("账号或密码错误");
                }
                if (res.RetCode == 18) {
                  this.$message.error("未查询到此用户");
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            //子账号登录
            const data = {
              Username: this.loginForm.phonenumber,
              Password: md5(this.loginForm.password),
            };
            this.$store
              .dispatch("sub/login", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$router.push({
                    path: "/",
                  });
                } else {
                  this.$message.error(res.Message);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loginwx() {
      window.location.href = `
      https://open.weixin.qq.com/connect/qrconnect
			?appid=wx70dd623f59efd16f
			&redirect_uri=${encodeURIComponent("https://console.wumitech.com/#/login")}
			&response_type=code
			&scope=snsapi_login&state=${Math.ceil(Math.random() * 1000)}
			`;
    },
    setFocus() {
      if (this.loginForm.phonenumber === "") {
        this.$refs.phonenumber.focus();
      } else if (this.loginForm.password === "") {
        this.$refs.password.focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account-wrap {
  margin-top: 35px;
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-select .el-input {
    width: 130px;
  }
  ::v-deep .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .remenberpass {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .forgetpass {
    cursor: pointer;
    font-size: 14px;
    color: rgba(102, 102, 102);
    border-bottom: 1px solid rgba(102, 102, 102);
    &:hover {
      color: rgba(74, 99, 240, 0.93);
    }
  }

  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    background: rgba(88, 151, 242, 1);
  }
  .otherlogin {
    display: flex;
    height: 50px;
    margin-top: 10px;
    justify-content: space-around;
    .weixinlogin {
      cursor: pointer;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
    }
    .qqlogin {
      cursor: pointer;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
    }
    .wxtext {
      margin-left: 10px;
    }
    .qqtext {
      margin-left: 10px;
    }
    .icon-weixin {
      font-size: 16px;
      color: #28c445;
    }
    .icon-qq {
      font-size: 16px;
      color: #4da0ff;
    }
  }
  .accounttext {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    .register {
      color: rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
</style>